import React from 'react';

import cover from "../../../assets/Bilder/Zeichnungen/001cover.png";
import stock1 from "../../../assets/Bilder/Zeichnungen/002.png";
import stock2 from "../../../assets/Bilder/Zeichnungen/003.png";
import stock3 from "../../../assets/Bilder/Zeichnungen/004.png";
import stock4 from "../../../assets/Bilder/Zeichnungen/005.png";
import stock5 from "../../../assets/Bilder/Zeichnungen/006.png";
import stock6 from "../../../assets/Bilder/Zeichnungen/007.png";
import stock7 from "../../../assets/Bilder/Zeichnungen/008.png";
import stock8 from "../../../assets/Bilder/Zeichnungen/009.png";
import stock9 from "../../../assets/Bilder/Zeichnungen/010.png";
import stock10 from "../../../assets/Bilder/Zeichnungen/011.png";
import stock11 from "../../../assets/Bilder/Zeichnungen/012.png";
import stock12 from "../../../assets/Bilder/Zeichnungen/013.png";


import {GalleryItem} from "./gallery_item";


export const ZeichnungenGallery = () => {
    const title = "Zeichnen";

    const subtitle = <p>

        Meine künstlerische Arbeit beginnt nicht mit irgendwelchen Inhalten, Botschaften oder Erlebnissen, die darzustellen oder auszudrücken ich mir vornehmen würde, sondern auf der anderen Seite, beim Zeichnen selbst. Das Zeichnen ist für mich die grundlegende Form des Suchens, Probierens oder auch eines nicht planenden Geschehen-Lassens. Erst im weiteren Verlauf kommt die Frage auf: was ist das nun, wofür könnte das stehen, was kommt hier zum Ausdruck? Dann schon auch die Frage: „Ist das etwas, was ich ‚von mir geben‘ möchte, will ich das geäußert haben?“ Nicht zuletzt natürlich die Frage des Gelingens - ob der Inhalt durch die Form vermittelt oder ob er aufgesetzt erscheint.
        <br/>
        Eine Regel, die es gestatten würde, Formen aus Geschichten oder Geschichten aus Formen herzuleiten, kenne ich nicht.
        <br/>
        <br/>
        Manche  Arbeiten greifen zeitlich weit aus und führen Motive fort, die mich immer wieder beschäftigen. Sichtweisen, Einstellungen und emotionale Gewichtungen ändern sich und führen zu neuen Versionen in wechselnden Kontexten. Über die Jahre sind viele Blätter zusammengekommen, sowohl einzelne Zeichnungen als auch lange Reihen. Und oft war das Durchspielen eines Motivs drängender als das Ausführen von Werken in größeren Formaten und aufwändigeren Techniken.
        <br/>
        <br/>

        Was nicht möglich ist: Alle Einfälle, die mir überhaupt in den Sinn kommen könnten, vor mir ausgebreitet zu sehen und dann so viele unter den besten auszuwählen, als ich in meiner Lebensspanne wirklich ausführen würde können.




    </p>

    return GalleryItem(title, subtitle, cover, [stock1, stock2, stock3, stock4, stock5, stock6, stock7, stock8, stock9, stock10, stock11, stock12]);
}