import React from 'react';

import cover from "../../../assets/Bilder/irritationbilder/001.png";
import stock1 from "../../../assets/Bilder/irritationbilder/002.png";
import stock2 from "../../../assets/Bilder/irritationbilder/003.png";
import stock3 from "../../../assets/Bilder/irritationbilder/004.png";
import {GalleryItem} from "./gallery_item";

export const IrritationGallery = () => {
    const title = "Irritation: „ Die Anbetung des Lammes“";

    const subtitle = <p>
        Die Altäre und Fresken in den Kirchen und die Werke in den Sammlungen des Diözesanmuseums Brixen zeigen, dass Menschen in religiösen Darstellungen Sicherheit, Anleitung, Warnung, Trost finden. Im Dom leuchtet an Festtagen das Deckenfresko „Die Anbetung des Lammes“ von Paul Troger. Mit solchen Bildern bin ich aufgewachsen. Heute weiß ich: Es gibt gläubige Menschen in unterschiedlichen Religionsgemeinschaften und daneben gibt es Menschen, die ihre existentiellen Erfahrungen und das, was sie von der Welt zu wissen meinen, nicht mit den Gottesvorstellungen und Dogmen einer Religion zusammenzudenken vermögen.
        <br/>
        Für mich ist die Auffassung, dass ein Geschöpf seinem als allwissend und allmächtig gedachten Schöpfer gegenüber verantwortlich sein kann - und ist infolgedessen die christliche Lehre der Sündenvergebung durch das Kreuz - unverständlich und irritierend.
        <br/>
        <br/>
        Es bringt nicht viel, die Güte oder die Nicht-Existenz Gottes beweisen zu wollen. Man muss hoffen, dass konfessionell Gläubige, Agnostiker und humanistische Atheisten durch ihre unterschiedlichen Sichtweisen nicht daran gehindert werden, auch über Grenzen hinweg Mitgefühl zu empfinden und Gerechtigkeit zu entwickeln.




    </p>

    return GalleryItem(title, subtitle, cover, [stock1, stock2, stock3]);
}