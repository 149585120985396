
import pdf from "../../../assets/Texte/warumreden.pdf";
import {TextPreview} from "./text_preview";


export const WarumReden = () => {

    const title = "Aber warum überhaupt das Reden wichtig nehmen, wenn Kunst – und Werte allgemein – subjektiv sind? (2018)";


    const intro = <p>
        Die Überzeugung, dass Werte subjektiv sind, steht nicht im Widerspruch dazu und macht es nicht
        sinnlos, sich für die eigenen Werte einzusetzen und die Verbindung mit Menschen zu suchen, die
        ähnliche Werte vertreten. Die Subjektivität des Wertens schließt nicht aus, dass Menschen auf der
        Grundlage von geteilten Bedürfnissen und Interessen und Bildungsprozessen (vielleicht auch einer
        geteilten menschlichen Natur?) in dem, was ihnen wichtig ist oder was ihnen gefällt, übereinstimmen
        können.
    </p>

    return TextPreview(title, intro, pdf);
}