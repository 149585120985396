import React from 'react';
import './NavBar.css';
import { Link } from 'react-scroll';


import instagram from "../../assets/instagram.png";



export const NavBarMobile = () => {
	return (
		<nav className="nav-bar-container">

			<div className="nav-title-container">
				Hans Knapp
			</div>

			<div className='nav-link-container'>
				<div className='nav-link-container'>
					{/*		<Link className='nav-bar-link' offset={-50} to="home" spy={true} smooth={true}>Home</Link>*/}
					{/*<Link className='nav-bar-link' offset={-50} to="kunst" spy={true} smooth={true}>Kunst</Link>*/}
					{/*<Link className='nav-bar-link' offset={-50} to="philosophie" spy={true} smooth={true}>Philosophie</Link>*/}
					{/*<Link className='nav-bar-link' offset={-50} to="ordnen" spy={true} smooth={true}><i>ordnen</i></Link>*/}
					{/*<Link className='nav-bar-link' offset={-50} to="biografie" spy={true} smooth={true}>Biografie</Link>*/}
					{/*<Link className='nav-bar-link' offset={-50} to="kontakt" spy={true} smooth={true}>Kontakt</Link>*/}

					<a className='nav-bar-link' href="https://www.instagram.com/hansknappzeichnet/" rel="noreferrer" target="_blank"><img  className='instagram' src={instagram} alt="instagram"/></a>
				</div>
			</div>

		</nav>
	)
}