import React from 'react';

import cover from "../../../assets/Bilder/moebiusbandbilder/001.png";
import stock1 from "../../../assets/Bilder/moebiusbandbilder/002.png";
import stock2 from "../../../assets/Bilder/moebiusbandbilder/003.png";
import stock3 from "../../../assets/Bilder/moebiusbandbilder/004.png";
import stock4 from "../../../assets/Bilder/moebiusbandbilder/005.png";
import stock5 from "../../../assets/Bilder/moebiusbandbilder/006.png";
import stock6 from "../../../assets/Bilder/moebiusbandbilder/007.png";

import {GalleryItem} from "./gallery_item";

export const MoebiusbandGallery = () => {
    const title = "Das Möbiusband ist ein Ding mit nur einer Seite.";

    const subtitle = <p>

        Wenn wir auf dem Möbiusband vorwärts gehen, gelangen wir nach einer ‚Runde’ auf die Rückseite des
        Ausgangspunktes (wir stehen mit dem Kopf nach unten, und beim Betrachten der Umwelt sind oben und unten bzw.
        links und rechts vertauscht). Nach einer weiteren Runde stehen wir wieder oben, wie am Anfang unseres Weges.
        Dabei sind wir nie über den Wegrand hinausgegangen, wir haben keine Grenze überschritten.
        <br/><b>Peripatetiker</b> sind Menschen, die beim Philosophieren umhergehen oder beim Herumgehen philosophieren.
        Sie spüren dabei die Form des Bodens, und manchmal haben sie den Eindruck, sie würden „auf einem Möbiusband“
        wandeln.
        <br/>
        Das <b>vergoldete</b> Möbiusband als glitzernde Welt, auf der wir herumlaufen, einmal so und dann wieder so, und
        dann wieder … - eine Welt für „Sisyphos‘ Schwester“, verstanden als eine Welt, die nicht vom Kampf um die
        Erfüllung der physischen Grundbedürfnisse bestimmt wird, sondern von Reflexion und pretiöser Selbstdeutung.

    </p>

    return GalleryItem(title, subtitle, cover, [stock1, stock2, stock3, stock4, stock5, stock6]);
}