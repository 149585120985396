import React from 'react';
import {OrdnenGallery} from "./ordnen_gallery";

import cover from "../../assets/Bilder/buch/buchcover.png";

export const Ordnen = () => {

    return (<section id='ordnen'>
            <main className='ordnen-card'>
                <div className="container text-card-container">
                    <h1 className='section-header'>Künstlerbuch <i>ordnen</i></h1>
                    <div className="row">


                        <div  className="col-md-8">
                            <OrdnenGallery/>

                        </div>
                        <div className="col-md-4">
                            <p>
                                <i>ordnen</i> ist ein Künstlerbuch, der gedruckte Aggregatzustand
                                eines performativen und sich ständig erweiternden Zeichenraums, ein
                                Instrument der Navigation, um direkt und unmittelbar in den Kosmos
                                jahrzehntelanger Arbeit und tausender Miniaturen einzutauchen. Um
                                eigensinnige Umgebungen zu erfahren wie die in die Tiefe gebaute
                                Tholos mit integrierten Alphörnern oder die Möbiusschleife als
                                Laufband für Sisyphos’ Schwester. Um Gedankengängen zu folgen über
                                Fragen, die seit langem bewegen. Über Wollen und Denken, Schönes und
                                Gutes, Hässliches und Böses und vieles dazwischen. Über vertikale und
                                horizontale Verantwortung. Das zeichnerische Werk, Arbeiten und
                                Reflexionen eines lesenden Künstlers.
                                <br/>

                                <br/>

                            </p>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-3">
                            <div className="row">
                                <img style={{paddingRight: '30px'}}
                                    src={cover}
                                />
                                <p></p>
                                <div/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row container">
                                <table>
                                    <tr><h4><i>ordnen</i></h4></tr>
                                    <th>Das zeichnerische Werk, Arbeiten und Texte</th>
                                    <tr>
                                        <td>896 Seiten</td>
                                    </tr>
                                    <tr>
                                        <td>Hartband mit zahlreichen Farbabbildungen<br/><br/></td>
                                    </tr>
                                    <tr>
                                        Grafik Design: Andrea Muheim, Lioba Wackernell <br/>
                                        <a href="http://www.granitdesign.eu/#HansKnapp"
                                        >granitdesign.eu</a
                                        ><br/><br/>

                                    </tr>
                                    <tr>
                                        <td>ISBN: 978-3-85415-609-3</td>
                                    </tr>
                                    <tr>
                                        <td>€ 49,–</td>
                                    </tr>
                                    <tr>
                                        <td>Ritterverlag</td>
                                    </tr>
                                    <tr>
                                        <td><i>Erschienen im Oktober 2020</i></td>
                                    </tr>
                                    <tr>
                                        <a
                                            href="https://www.ritterbooks.com/produkt/ordnen-das-zeichnerische-werk-arbeiten-und-texte/"
                                        >Bestellen</a
                                        >
                                    </tr>
                                </table>
                                <div/>
                            </div>
                        </div>


                    </div>
                </div>
            </main>

        </section>

    );
}