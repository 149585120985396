export const TextPreview = (title, intro, pdf) => {
    const openResume = () => {
        window.open(pdf, "_blank")
    }
    return (
        <div className="col-md-4">
            <div className="work-box">
                <div className="work-content">
                    <div className="row">
                        <div className="col-md-11">
                            <h2 className="w-title">{title}</h2>
                            <div className="w-more">
                          <span className="w-ctegory">
                              {intro}
                              <a id='cbh-first-child' href={pdf} onClick={openResume} rel="noopener noreferrer" target="_blank">[...]</a>

                          </span>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}