import React from 'react';

export const GalleryItem = (title, subtitle, cover, images) => {
    return (
        <div className="col-md-4">
            <div className="work-box">
                <a href={cover} data-lightbox="gallery-vmarine">
                    <div className="work-img">
                        <img src={cover} alt="" className="img-fluid"/>
                    </div>
                    <div className="work-content">
                        <div className="row">
                            <h2 className="w-title">{title}</h2>
                            <div className="w-more">
                          <span className="w-ctegory">
                            {subtitle}
                          </span>
                            </div>
                        </div>
                    </div>
                </a>
                {images.map((image) => <a
                    key={image.key}
                    href={image}
                    data-lightbox="gallery-vmarine"
                    style={{display: "none"}}
                >
                    jsx-a11y/anchor-has-content warning
                </a>)}
            </div>
        </div>
    );
}