import React from 'react';
// import './Kontakt.css';

export const Kontakt = () => {
    return (
        <section id='kontakt'>
            <main>
                <h1 className='section-header'>Kontakt</h1>

                <div className='text-card-container'>
                    <div>
                        <p>
                            Sie erreichen mich per E-mail unter <a href="mailto:hans.knapp@mailbox.org">hans.knapp@mailbox.org</a>, oder telefonisch: +39 3343285995.
                        </p>
                    </div>

                </div>
            </main>

        </section>

    );
}