import React from 'react';

import stock from "../../assets/Bilder/buch/buch1.jpg";
import stock1 from "../../assets/Bilder/buch/buch2.jpg";
import stock2 from "../../assets/Bilder/buch/buch3.jpg";
import stock3 from "../../assets/Bilder/buch/buch4.jpg";
import stock4 from "../../assets/Bilder/buch/buch5.jpg";
import stock5 from "../../assets/Bilder/buch/buch6.jpg";


export const OrdnenGallery = () => {
    return (
        <div>
            <div className="work-box">
                <a href={stock} data-lightbox="gallery-vmarine">
                    <div className="work-img">
                        <img src={stock} alt="" className="img-fluid" />
                    </div>
                </a>
                <a
                    href={stock1}
                    data-lightbox="gallery-vmarine"
                    style={{ display: "none" }}
                >
                    jsx-a11y/anchor-has-content warning
                </a>
                <a
                    href={stock2}
                    data-lightbox="gallery-vmarine"
                    style={{ display: "none" }}
                >
                    jsx-a11y/anchor-has-content warning
                </a>
                <a
                    href={stock3}
                    data-lightbox="gallery-vmarine"
                    style={{ display: "none" }}
                >
                    jsx-a11y/anchor-has-content warning
                </a>
                <a
                    href={stock4}
                    data-lightbox="gallery-vmarine"
                    style={{ display: "none" }}
                >
                    jsx-a11y/anchor-has-content warning
                </a>
                <a
                    href={stock5}
                    data-lightbox="gallery-vmarine"
                    style={{ display: "none" }}
                >
                    jsx-a11y/anchor-has-content warning
                </a>
            </div>
        </div>
);
}