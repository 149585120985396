import React from 'react';
import {MitteParadies} from "./previews/mitte_paradies";
import {WarumReden} from "./previews/warum_reden";
import {SchoenUndGut} from "./previews/schoen_und_gut";
import {Verantwortlich} from "./previews/verantwortlich";

export const Philosophie = () => {
    return (
        <section className='container' id='philosophie'>
            <main className='philosophie-card'>
                <div className='philosophie-card-container'>
                    <h1 className='section-header'>Philosophie</h1>
                    <div className="row">

                        <SchoenUndGut/>
                        <Verantwortlich/>
                        <MitteParadies/>
                        <WarumReden/>
                    </div>
                </div>
            </main>

        </section>

    );
}