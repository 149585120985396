import React from 'react';

import stock from "../../../assets/Bilder/blätterbilder/001.png";
import stock1 from "../../../assets/Bilder/blätterbilder/002.png";
import stock2 from "../../../assets/Bilder/blätterbilder/003.png";
import stock3 from "../../../assets/Bilder/blätterbilder/004.png";
import {GalleryItem} from "./gallery_item";


export const BlaetterGallery = () => {
    const cover = stock;
    const images = [stock1, stock2, stock3];
    const title = "Blätter: „Woher kommen wir? Wer sind wir? Wohin gehen wir?“";

    const subtitle = <p>
        Völs am Schlern ist ein gepflegter Fremdenverkehrsort. Auf den Plätzen im Ort und an den Spazier- und Wanderwegen in der Umgebung gibt es rund 200 Ruhebänke. An jeder dieser Bänke bringe ich eine Plakette mit Text an.
        <br/>
        In Büchern, Zeitschriften und im Internet habe ich Texte gesucht und gefunden, welche die Fragen „Woher kommen wir? Wer sind wir? Wohin gehen wir? enthalten – wörtlich oder in sinnverwandten Variationen. Für das Gemeinschaftsprojekt mit 8 Künstler*innen „Vor Ort“ in Völs habe ich 200 Textstellen ausgewählt.
        <br/>

        Keine Plakette ist genau gleich wie irgendeine andere, aber es geht immer um dieselben Fragen. Philosophen, Päpste und Theologen, Dichter und Schriftsteller, Maler, Filmregisseure, Rezensenten, Journalisten, Psychologen, Anthropologen, Historiker, Physiker, Kosmologen, Genetiker, Hirnforscher, Politiker, Comiczeichner, Ufologen, Esoteriker, Kabarettisten, Schauspieler, Alpinisten, Lehrer, Langstreckenläufer, Studenten, Senioren, Liedermacher, Managementberater, Blogger, Festredner, … Fromme und Spötter fragen, woher wir kommen, wer oder was wir sind und wohin wir gehen.
    </p>

    return GalleryItem(title, subtitle, cover, images);
}