import React from 'react';


import cover from "../../../assets/Bilder/tholosbilder/001.png";
import stock1 from "../../../assets/Bilder/tholosbilder/002.png";
import stock2 from "../../../assets/Bilder/tholosbilder/003.png";
import stock3 from "../../../assets/Bilder/tholosbilder/004.png";
import stock4 from "../../../assets/Bilder/tholosbilder/005.png";
import stock5 from "../../../assets/Bilder/tholosbilder/006.png";
import stock6 from "../../../assets/Bilder/tholosbilder/007.png";
import stock7 from "../../../assets/Bilder/tholosbilder/008.png";
import stock8 from "../../../assets/Bilder/tholosbilder/009.png";
import stock9 from "../../../assets/Bilder/tholosbilder/010.png";
import stock3b from "../../../assets/Bilder/tholosbilder/003b.png";


import {GalleryItem} from "./gallery_item";


export const TholosGallery = () => {
    const title = "Das Tholos-Projekt:  „Raum und Licht und kleines Schauspiel“";

   const subtitle = <p>
       <b>Tholos 1</b> <br/>Die Tholos als ein vom Menschen in die Natur eingefügtes Zeichen, ein in die Tiefe gebautes Bergheiligtum. Idealer Standort wäre eine Landschaft bei Sterzing, in der Nähe von sieben Bergseen. Die Tholos würde den freien Rundblick nicht stören, da nur drei Stufen des Unterbaus und darauf ein etwa 90 cm hoher Mauerring (ungefähr 8 m im Durchmesser, wie die Tholos von Delphi) sich über den Boden erheben. Die drei oberen Enden der Alphörner ragen etwa 1,5 m aus dem Boden. Wanderer nähern sich und schauen, sie erleben den suggestiven Eindruck des weiten und tiefen Zylinders mit der Spirale, die den Blick hinableitet auf den großen Wasserspiegel. Der Spiegel verdoppelt die Tiefe und zeigt weit unten Himmel und Wolken und – klein – nein, nicht ein Götterbild, sondern das eigene Bild.
       <br/>
       <b>Tholos 2</b> <br/>Aus der Tiefe, aus einer Luftkammer im Wasser, schauen wir im Wechsel der Jahreszeiten hinauf zum Offenen.
       Seelen-Zeiten, in denen wir hinauf und hinaus wollen, dorthin, wo wir im Licht eine weite und offene Welt vermuten, in der wir vielfältig handeln und erleben könnten.  Und Seelen-Zeiten, in denen Eis sich Schicht auf Schicht schwer und undurchdringlich auf unser Gemüt legt.  Vielleicht mögen wir es manchmal auch, in der Höhle zu bleiben und uns nicht ins Spiel des Lebens hineinziehen zu lassen. Und freuen uns an den Spiegelungen in den Luftblasen-Linsen in zarteren Eisschichten. Und unerwartet beginnt die Luft im Raum zu schwingen: ein verhaltenes Anspielen der Alphörner, schwermütige Tonfülle und schauerliches Widerhallen, skurriles Abklingen zum Scherzando, erhabene Stille.

   </p>;


    return GalleryItem(title, subtitle, cover, [stock1, stock2, stock3,stock3b, stock4, stock5, stock6, stock7, stock8, stock9]);
}