import React from 'react';
import {TholosGallery} from "./galleries/tholos_gallery";
import {IrritationGallery} from "./galleries/irritation_gallery";
import {MoebiusbandGallery} from "./galleries/moebiusband_gallery";
import {BlaetterGallery} from "./galleries/blaetter_gallery";
import {ZeichnungenGallery} from "./galleries/zeichnungen_gallery";



export const Kunst = () => {
    return (
        <section className='portfolio-mf sect-pt4 route' id='kunst'>
            <h1 className='section-header'>Kunst</h1>
            <div className="container">
                <div className="row">
                    <ZeichnungenGallery/>
                    <TholosGallery/>
                    <MoebiusbandGallery/>
                    <IrritationGallery/>
                    <BlaetterGallery/>
                </div>
            </div>
        </section>

    );
}
