
import pdf from "../../../assets/Texte/schönundgut.pdf";
import {TextPreview} from "./text_preview";


export const SchoenUndGut = () => {

    const title = "„schön und gut“\n" +
        "und hässlich und böse, und vieles dazwischen (2020)";



    const intro = <p>
        Dass etwas wahr ist, heißt noch lange nicht, dass
        wir es auch wissen, und noch viel weniger heißt
        es, dass wir uns im Handeln danach richten. Dasselbe würde gewiss auch im Falle von Werten bzw.
        Werturteilen gelten, wenn es ’wahre’ Werte gäbe.
        Trotzdem wünsche ich manchmal, ich könnte beweisen, dass das, was für mich einen großen Wert
        hat, ’objektiv’ einen großen Wert hat.
        <br/>

        Der Konjunktiv im vorletzten Satz zeigt an,
        dass ich nicht an objektive Werte ’glaube’. Es gibt
        für mich Werte, über die ich nicht mit mir reden
        lasse, aber Werte sind nun einmal keine Sachen, die
        objektiv oder wahr oder richtig sein können wie
        Beschrei-bungen oder in gewissem Sinne Wahrnehmungs-inhalte. Unser Bewerten hat keinen eigenen
        Zugang zu den Dingen, es kann nur an dem ansetzen,
        was die nach außen gerichteten Sinne und was innere Prozesse, was Reflexe, Gedächtnis, Vorstellung
        und Wissen oder Meinen bereitstellen.

    </p>
   return TextPreview(title, intro, pdf);
}