
import pdf from "../../../assets/Texte/verantwortlich.pdf";
import {TextPreview} from "./text_preview";


export const Verantwortlich = () => {

    const title = "verantwortlich für den Inhalt: ich, Hans Knapp (2020)";


    const intro = <p>"[...] Zum Beispiel Klimawandel:<br/>
        <b>How dare you?</b> (Greta Thunberg am 23. September
        2019 auf dem UN-Klimagipfel) – ein Appell an <b>Verantwortliche</b>, wie er dringlicher und fordernder
        nicht sein könnte.<br/>
        Daran, dass die gegenwärtige Erderwärmung
        maßgeblich durch Aktivitäten von Menschen
        befördert wird, besteht für die Wissenschaft kein
        ernsthafter Zweifel. Greta Thunberg macht in ihrer
        Rede die versammelten Politiker dafür verantwortlich, die Erkenntnisse und Warnungen nicht
        ernstgenommen zu haben. Sie hätten anders
        handeln sollen, als sie es getan haben, und dies zu
        fordern, setzt die Annahme voraus, dass sie anders
        handeln hätten können.
        Darüber, wie dieses <b>„sie hätten auch
        anders handeln können“</b> genau zu verstehen ist,
        streiten Philosophen und Theologen von jeher, und
        heute lebt die Auseinandersetzung auch in Debatten
        innerhalb der und zwischen den Wissenschaften und Menschen weiter.</p>


    return TextPreview(title, intro, pdf);
}