import React from 'react';
// import './Biografie.css';


import bild from "../../assets/Bilder/biografie/papi.png";
import bild2 from "../../assets/Bilder/biografie/blocks.png";


export const Biografie = () => {
    return (
        <section id='biografie'>
            <main className='biografie-card'>
                <h1 className='section-header'>Biografie</h1>
                <div className='text-card-container container'>
                    <div className="row">
                        <div className="col-sm-8">
                        <table>
                            <tr>
                                <th>Vita</th>
                            </tr>
                            <tr>
                                <td>1945</td>
                                <td>Geboren in Brixen</td>
                            </tr>
                            <tr>
                                <td>1964</td>
                                <td>Matura in Brixen</td>
                            </tr>
                            <tr>
                                <td>1965/66</td>
                                <td>Militärdienst in Palermo und Messina</td>
                            </tr>
                            <tr>
                                <td>1966-70</td>
                                <td>
                                    Kunstakademien in München und Wien; Diplom bei Prof. Max Melcher,
                                    Wien
                                </td>
                            </tr>
                            <tr>
                                <td>1970/71</td>
                                <td>Universität Padua; Mathematik</td>
                            </tr>
                            <tr>
                                <td>1971/72</td>
                                <td>Universität Innsbruck: Philosophie</td>
                            </tr>
                            <tr>
                                <td>1972-92</td>
                                <td>Lehrer</td>
                            </tr>
                            <tr>
                                <td>1992-heute</td>
                                <td>freischaffender Künstler</td>
                            </tr>
                            <tr>
                                <td>2020</td>
                                <td>Erscheinen des Künstler-Buches <i>"ordnen"</i>.</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Einzelausstellungen</th>
                            </tr>
                            <tr>
                                <td width="18%">1985</td>
                                <td>Rathausgalerie Brixen</td>
                            </tr>
                            <tr>
                                <td>1992</td>
                                <td>Ragenhaus Bruneck</td>
                            </tr>
                            <tr>
                                <td>1993</td>
                                <td>Galerie Museum Bozen</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>2005</td>
                                <td>Galerie Prisma Bozen</td>
                            </tr>
                            <tr>
                                <td>2007</td>
                                <td>Kunstforum Unterland</td>
                            </tr>
                            <tr>
                                <td>2011</td>
                                <td>Stadtgalerie Brixen</td>
                            </tr>
                            <tr>
                                <td>2014</td>
                                <td>Hofburg Brixen/Kunst Meran/o arte</td>
                            </tr>
                            <tr>
                                <td>2021</td>
                                <td>Buchvorstellung mit Ausstellung Museion Bozen</td>
                            </tr>
                            <tr>
                                <th>Beteiligungen</th>
                            </tr>
                            <tr>
                                <td>1988</td>
                                <td>SKB: Schloss Gandegg Eppan</td>
                            </tr>
                            <tr>
                                <td>1994</td>
                                <td>Regensburg, Brixner Künstler</td>
                            </tr>
                            <tr>
                                <td>1994</td>
                                <td>Priesterseminar Brixen</td>
                            </tr>
                            <tr>
                                <td>1998</td>
                                <td>St. Pölten, Südtiroler Kulturwochen</td>
                            </tr>
                            <tr>
                                <td>2006</td>
                                <td>„vor Ort“, Völs am Schlern</td>
                            </tr>
                            <tr>
                                <td>2006</td>
                                <td>SKB: Kunst und Sakralraum, Hofburg Brixen</td>
                            </tr>
                            <tr>
                                <td>2009</td>
                                <td>Landesausstellung Labyrinth::Freiheit</td>
                            </tr>
                            <tr>
                                <td>2009</td>
                                <td>NEW ENTRIES! Museion Bozen</td>
                            </tr>
                            <tr>
                                <td>2011</td>
                                <td>Regensburg, Kooperation Brixen - Regensburg</td>
                            </tr>
                            <tr>
                                <td>2016</td>
                                <td>SKB: Garten / Giardino, Hofburg Brixen</td>
                            </tr>
                            <tr>
                                <th>Wettbewerbe</th>
                            </tr>
                            <tr>
                                <td>1998</td>
                                <td>Brixen, Großer Graben, Brunnen :3. Preis</td>
                            </tr>
                            <tr>
                                <td>1998</td>
                                <td>
                                    Ex-Grandhotel Toblach (zusammen mit Dr. Arch. Elisabeth Schatzer)
                                </td>
                            </tr>
                            <tr>
                                <td>2002</td>
                                <td>Kunstprojekt Marie Curie, Meran</td>
                            </tr>
                            <tr>
                                <th>Aufträge</th>
                            </tr>
                            <tr>
                                <td>1999/2000</td>
                                <td>
                                    Künstlerische Gestaltung des Wintergartens im Realgymnasium „J.Ph.
                                    Fallmerayer“ in Brixen (Projekt „Peripatoimen - Gehen wir einmal“)
                                </td>
                            </tr>
                            <tr>
                                <td>2000/2001</td>
                                <td>
                                    Mitarbeit mit Designer Societät Stuttgart an der künstlerischen
                                    Gestaltung der Berufsschule „J. Tschuggmall“ in Brixen
                                </td>
                            </tr>
                        </table>
                        </div>
                        <div className="col-sm-4">
                            <img style={{width:'100%'}}
                                 src={bild}
                            />
                            <br/>
                            <br/>

                            <img style={{width:'100%'}}
                                 src={bild2}
                            />
                        </div>
                    </div>

                </div>
            </main>

        </section>

    );
}