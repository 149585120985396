
import pdf from "../../../assets/Texte/mitteparadies.pdf";
import {TextPreview} from "./text_preview";


export const MitteParadies = () => {

    const title = "Die Mitte des Paradieses (2018)";

    const intro = <p>
        Mit charmanter Beredsamkeit weiß André Heller die vielfältigen Wohltaten von Gärten zu
        preisen. Bei der Präsentation seines Exposés im Forum Brixen sollten die Zuhörer davon
        überzeugt werden, dass der Hofburggarten beides sein wird: ein Ort der Ruhe und ein
        Magnet für den Tourismus. Ein Garten, der dem Verlangen nach Sammlung und Spiritualität
        genauso gerecht werden könne wie dem Verlangen nach Amüsement und dem Wunsch, in
        Erstaunen versetzt zu werden. Ich kann mir nicht vorstellen, wie das möglich sein soll, aber
        ich hatte den Eindruck, dass ein großer Teil der Anwesenden begeistert war, fast
        einschüchternd begeistert. Welche Kultur und welche Kunst bekämen wir, wenn die
        öffentliche Hand bei Investitionen im kulturellen Bereich die Meinung von Experten als
        unwichtig betrachten und die Entscheidungen direkt der jeweils bestehenden
        Mehrheitsmeinung überlassen würde?

    </p>
    return TextPreview(title, intro, pdf);
}