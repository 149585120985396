import React from 'react';

import { NavBar } from '../NavBar/NavBar';
import { Home } from '../Home/Home';
import {Kunst} from "../Kunst/Kunst";
import {Philosophie} from "../Philosophie/Philosophie";
import {Ordnen} from "../Ordnen/Ordnen";
import {Biografie} from "../Biografie/Biografie";
import {Kontakt} from "../Kontakt/Kontakt";
import {NavBarMobile} from "../NavBar/NavBarMobile";

export const Main = (props) => {
	const copyright= "© Hans Knapp 2023";
	return (
		<div>
			{props.width < 800 ? <NavBarMobile /> : <NavBar />}
			<Home />
			<Kunst />
			<Philosophie />
			<Ordnen />
			<Biografie />
			<Kontakt />
			<div className="text-card-container" style={{textAlign: "center"}}>{copyright}</div>
		</div>
	);
}

